import { FormProvider } from 'react-hook-form'

import { Button, Card, Form, SelectOption, Stack, Toast } from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'
import { TAccountActivationStatus } from '@sga/shopify-payments-types'

type TProps = {
  goBack: () => any
  status: TAccountActivationStatus
}
GPWebPayAccountSettings.segment = segment('gpwebpay')

export function GPWebPayAccountSettings({ goBack, status }: TProps) {
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm((api, formValues) => api.saveConfig(formValues))

  if (status === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          <SettingsLanguage languages={getLanguages()} />
          <SettingsPaymentLabel />
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={goBack}>{getTranslation('Zpět', 'Go Back')}</Button>
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: 'Automaticky' },
    { value: 'cs', label: 'Čeština' },
    { value: 'en', label: 'Angličtina' },
    { value: 'sk', label: 'Slovenština' },
    { value: 'de', label: 'Němčina' },
    { value: 'ru', label: 'Ruština' },
    { value: 'pl', label: 'Polština' },
    { value: 'hu', label: 'Maďarština' },
    { value: 'fr', label: 'Francouzština' },
    { value: 'ro', label: 'Rumunština' },
    { value: 'bg', label: 'Bulharština' },
    { value: 'hr', label: 'Chorvatština' },
    { value: 'it', label: 'Italština    ' },
  ]
}
